<div class="flex flex-col mb-6">
  <div class="font-syne text-2xl font-semibold mb-5">Tilvalgsdækninger</div>

  <div
    (click)="toggleParkingCoverage()"
    [ngClass]="
      parkingCoverage
        ? 'border-[#32C895] bg-[#F1FCF9]'
        : 'border-gray-300 bg-transparent'
    "
    class="my-2 flex flex-wrap justify-between border shadow p-5 rounded-xl cursor-pointer"
  >
    <div class="flex items-center justify-center">
      <i
        [ngClass]="parkingCoverage ? 'text-teal-450' : 'text-gray-200'"
        class="fa-solid fa-circle-check mr-3 text-xl"
      ></i>
      <p class="font-semibold font-syne truncate text-base">
        Parkeringsdækning
      </p>
    </div>

    <!-- Pricing and Tooltip Div -->
    <div class="flex items-center">
      <div class="flex items-center ml-8">
        <p
          [ngClass]="{ 'quantico-text': parkingCoverage }"
          class="text-2xl quantico-font truncate"
        >
          {{ getHelvetiaParkingCoveragePrice() | formatNumber }}
          <span *ngIf="!showYearlyPrice" class="text-xs">DKK/md.</span>
          <span *ngIf="showYearlyPrice" class="text-xs">DKK/år</span>
        </p>

        <!-- Info Icon Tooltip -->
      </div>
      <div class="relative">
        <i
          (mouseover)="showTooltip_ParkingCoverage = true"
          (mouseout)="showTooltip_ParkingCoverage = false"
          class="fas fa-regular fa-circle-info text-gray-300 ml-4"
        ></i>
        <!-- Tooltip Content -->
        <span
          class="text-sm z-10 bg-white border shadow-lg rounded-md absolute bottom-full left-1/2 transform -translate-x-1/2 px-6 py-4 w-96"
          [class.hidden]="!showTooltip_ParkingCoverage"
        >
          <p class="font-medium mb-4">Parkeringsdækning</p>
          <p class="mb-2 text-xs">
            Parkeringsskader forsikres op til 14.900 kr. pr. skade med en
            selvrisiko på kun 380 kr. Dækningen er tilgængelig for alle biltyper
            uanset alder og gælder op til to skader årligt.
          </p>
        </span>
      </div>
    </div>
  </div>

  <div
    (click)="toggleRoadsideAssistance()"
    [ngClass]="
      roadsideAssistance
        ? 'border-[#32C895] bg-[#F1FCF9]'
        : 'border-gray-300 bg-transparent'
    "
    class="my-2 flex flex-wrap justify-between border shadow p-5 rounded-xl cursor-pointer"
  >
    <div class="flex items-center justify-center">
      <i
        [ngClass]="roadsideAssistance ? 'text-teal-450' : 'text-gray-200'"
        class="fa-solid fa-circle-check mr-3 text-xl"
      ></i>
      <p class="font-semibold font-syne truncate text-base">Vejhjælp</p>
    </div>

    <!-- Pricing and Tooltip Div -->
    <div class="flex items-center">
      <div class="flex items-center ml-8">
        <p
          [ngClass]="{ 'quantico-text': roadsideAssistance }"
          class="text-2xl quantico-font truncate"
        >
          {{ getHelvetiaRoadsideAssistancePrice() | formatNumber }}
          <span *ngIf="!showYearlyPrice" class="text-xs">DKK/md.</span>
          <span *ngIf="showYearlyPrice" class="text-xs">DKK/år</span>
        </p>

        <!-- Info Icon Tooltip -->
      </div>
      <div class="relative">
        <i
          (mouseover)="showTooltip_RoadsideAssistance = true"
          (mouseout)="showTooltip_RoadsideAssistance = false"
          class="fas fa-regular fa-circle-info text-gray-300 ml-4"
        ></i>
        <!-- Tooltip Content -->
        <span
          class="text-sm z-10 bg-white border shadow-lg rounded-md absolute bottom-full left-1/2 transform -translate-x-1/2 px-6 py-4 w-96"
          [class.hidden]="!showTooltip_RoadsideAssistance"
        >
          <p class="font-medium mb-4">Vejhjælp</p>
          <p class="mb-2 text-xs">
            Autohjælp dækker gratis assistance ved bilproblemer som
            startvanskeligheder, punktering, brændstofmangel eller behov for
            bugsering til værksted.
          </p>
        </span>
      </div>
    </div>
  </div>

  <div
    (click)="toggleGlassCoverCoverage()"
    [ngClass]="
      glassCover
        ? 'border-[#32C895] bg-[#F1FCF9]'
        : 'border-gray-300 bg-transparent'
    "
    class="my-2 flex flex-wrap justify-between border shadow p-5 rounded-xl cursor-pointer"
  >
    <div class="flex items-center justify-center">
      <i
        [ngClass]="glassCover ? 'text-teal-450' : 'text-gray-200'"
        class="fa-solid fa-circle-check mr-3 text-xl"
      ></i>
      <p class="font-semibold font-syne truncate text-base">Glasdækning</p>
    </div>

    <!-- Pricing and Tooltip Div -->
    <div class="flex items-center">
      <div class="flex items-center ml-8">
        <p
          [ngClass]="{ 'quantico-text': glassCover }"
          class="text-2xl quantico-font truncate"
        >
          {{ getHelvetiaGlassCoverPrice() | formatNumber }}
          <span *ngIf="!showYearlyPrice" class="text-xs">DKK/md.</span>
          <span *ngIf="showYearlyPrice" class="text-xs">DKK/år</span>
        </p>

        <!-- Info Icon Tooltip -->
      </div>
      <div class="relative">
        <i
          (mouseover)="showTooltip_GlassCover = true"
          (mouseout)="showTooltip_GlassCover = false"
          class="fas fa-regular fa-circle-info text-gray-300 ml-4"
        ></i>
        <!-- Tooltip Content -->
        <span
          class="text-sm z-10 bg-white border shadow-lg rounded-md absolute bottom-full left-1/2 transform -translate-x-1/2 px-6 py-4 w-96"
          [class.hidden]="!showTooltip_GlassCover"
        >
          <p class="font-medium mb-4">Glasdækning</p>
          <p class="mb-2 text-xs">
            Det er muligt at tilvælge glasdækning som dækker skader på
            køretøjets ruder (frontrude, bagrude og sideruder, samt soltag).
            Selvrisikoen vil være 1.250 kr.
          </p>
        </span>
      </div>
    </div>
  </div>

  <!-- #### Mersalg ####-->
  <div
    *ngIf="
      (vehicleFromDmr.fuelType == 'El' ||
        vehicleFromDmr.pluginHybrid == true) &&
      allHaveToggleChargingStandAllowed
    "
    class="mt-6"
  >
    <div class="font-syne text-2xl font-semibold mb-5">Mersalg</div>

    <div
      (click)="toggleChargingStandCoverage()"
      [ngClass]="{ 'border-[#32C895] bg-[#F1FCF9]': chargingStand }"
      class="my-2 border shadow p-5 rounded-xl cursor-pointer"
    >
      <div class="flex">
        <p>
          <i
            [ngClass]="chargingStand ? 'text-teal-450' : 'text-gray-200'"
            class="fa-solid fa-circle-check mr-3 text-xl"
          ></i>
        </p>
        <div>
          <p class="font-semibold">Ladestander</p>
          <p class="text-gray-500">
            Få et uforpligtet tilbud på en ladeløsning.
          </p>
          <p class="text-gray-500">
            <span *ngIf="textDirectedToCustomer">Du</span>
            <span *ngIf="!textDirectedToCustomer">Din kunde</span> vil blive
            ringet op af Energi Fyn inden for 1-2 hverdage.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  (click)="nextStep()"
  type="button"
  class="py-3 px-4 shadow-lg font-inter inline-flex items-center gap-x-1 text-sm rounded-full border border-transparent bg-teal-450 text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
>
  Næste
</button>
