import { Component } from '@angular/core';
import { DealerService } from 'projects/shared/src/public-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'forhandler-portal';

  constructor() {}

  ngOnInit() {}
}
