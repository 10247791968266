import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent {
  //Closes the option if clicked outside of component
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const targetId = (event.target as HTMLElement).id;

    if (targetId !== this.index) {
      this.showOptions = false;
    }
  }

  filteredOptions: any[] = [];

  ngOnInit() {
    this.filteredOptions = this.options;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  @Output() output = new EventEmitter<any>();

  @Input() selectedValue: string = '';
  @Input() title: string = '';
  @Input() options: any = [];
  @Input() placeholder: string = '';
  @Input() errorInDropdown: boolean = false;
  @Input() index: string = '';
  @Input() filterFunction = (option: any, selectedValue: string): boolean => {
    return option.name.toLowerCase().includes(selectedValue.toLowerCase());
  };

  showOptions: boolean = false;

  filterOptions() {
    // remove error when changed or focused

    this.filteredOptions = this.options.filter((option: any) =>
      this.filterFunction(option, this.selectedValue)
    );
    // make sure to option is open when typing
    this.showOptions = true;

    // Makes sure the selectedOption is reset, if there is typed in ininput
    this.output.emit({ name: this.selectedValue, value: 0 });
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  optionClick(selectedOption: any) {
    // KSF have they own values "valuesText"
    this.selectedValue = selectedOption.name || selectedOption.valueText;
    this.output.emit(selectedOption);
    this.toggleOptions();
  }

  preventFocus(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}
