<div class="sm:max-w-lg sm:w-full sm:mx-auto min-w-[350px]">
  <div class="flex flex-col bg-white border shadow-sm pointer-events-auto">
    <div class="flex justify-center items-center py-3 px-4 border-b">
      <div class="flex">
        <h3 class="mx-2 font-inter">
          {{ title }}
        </h3>
        <ng-container *ngIf="type === 'success'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="green"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-circle-check"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="m9 12 2 2 4-4" />
          </svg>
        </ng-container>

        <ng-container *ngIf="type === 'warrning'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-circle-alert"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" x2="12" y1="8" y2="12" />
            <line x1="12" x2="12.01" y1="16" y2="16" />
          </svg>
        </ng-container>

        <ng-container *ngIf="type === 'error'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="red"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-ban"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="m4.9 4.9 14.2 14.2" />
          </svg>
        </ng-container>
      </div>
    </div>
    <div class="p-4 overflow-y-auto">
      <p class="mt-1 text-gray-800 text-center">
        {{ message }}
      </p>
    </div>
    <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
      <button
        (click)="confirm()"
        type="button"
        class="py-2 px-3 focus:outline-none inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-teal-500 text-white hover:bg-teal-600 disabled:opacity-50 disabled:pointer-events-none"
      >
        Ok
      </button>
    </div>
  </div>
</div>
