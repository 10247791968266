<div class="flex flex-col justify-center items-center h-full">
  <div
    class="font-syne font-semibold text-4xl leading-9 tracking-tighter text-teal-450"
  >
    Forsikring for
  </div>

  <div class="mt-10">
    <div
      class="font-syne font-semibold text-3xl md:text-4xl lg:text-5xl leading-9 md:leading-10 lg:leading-11"
    >
      {{ make }}
      {{ model }}
    </div>
  </div>

  <!-- SJF Text-->
  <p
    *ngIf="InsurranceCompany == 'sjf'"
    class="font-syne mt-5 text-center text-base"
  >
    Din kunde vil inden længe modtage en mail med et link til Forsia's
    hjemmeside, hvor forsikringsaftalen skal underskrives med MitID. Mailen
    indeholder en vejledning, der trin for trin forklarer, hvordan processen
    foregår.
  </p>

  <!-- Helvetia Text-->
  <p
    *ngIf="InsurranceCompany == 'helvetia'"
    class="font-syne mt-5 text-center text-base"
  >
    <span *ngIf="textDirectedToCustomer">Du</span>
    <span *ngIf="!textDirectedToCustomer"> Din kunde</span>
    modtager inden længe en e-mail med et link, hvor alle oplysningerne
    bekræftes og betalingen foretages. Helvetia sender derefter den nye
    forsikringspolice direkte til
    <span *ngIf="textDirectedToCustomer">din</span>
    <span *ngIf="!textDirectedToCustomer">kundens</span> e-Boks.
  </p>

  <!-- KSF Text-->
  <p
    *ngIf="InsurranceCompany == 'ksf'"
    class="font-syne mt-5 text-center text-base"
  >
    Kunden vil snart modtage en mail med et link til en kvitteringsside, hvor
    kunden nemt kan underskrive med sit MitID
  </p>

  <div *ngIf="InsurranceCompany == 'sjf'" class="mt-8">
    <img src="assets/images/sjf_logo.png" alt="Logo 1" class="h-14" />
  </div>

  <div *ngIf="InsurranceCompany == 'helvetia'" class="mt-8">
    <img src="assets/images/helvetia_logo.png" alt="Logo 1" class="h-14" />
  </div>

  <div *ngIf="InsurranceCompany == 'ksf'" class="mt-8">
    <img src="assets/images/ks_logo.png" alt="Logo 1" class="h-14" />
  </div>

  <button
    (click)="reset()"
    type="button"
    class="py-3 px-4 shadow-lg mt-10 font-syne inline-flex items-center gap-x-1 text-sm font-semibold rounded-full border border-transparent bg-teal-450 text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
  >
    Ny forsikring
  </button>
</div>
