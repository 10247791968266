<!-- ######################## -->
<!-- ### Fake forsia card ### -->
<!-- ######################## -->
<div class="relative h-full">
  <p
    class="absolute z-30 font-syne gradient-bg text-3xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"
  >
    {{ msg }}
  </p>
  <div
    class="group blur-sm z-10 flex flex-col h-full bg-white border border-teal-450 shadow-md rounded-xl"
  >
    <div class="relative lg:col-span-1 md:col-span-full h-full">
      <div
        class="grid h-full xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 sm:grid-cols-1"
      >
        <div class="flex flex-col bg-[#9FF0DC] bg-opacity-10 relative">
          <div class="flex-1">
            <div class="h-16 flex justify-center items-center mt-5">
              <img
                loading="lazy"
                *ngIf="company == 'Forsia'"
                src="assets/images/sjf_logo.png"
                alt="Logo 1"
                class="h-16"
              />

              <img
                loading="lazy"
                *ngIf="company == 'Helvetia'"
                src="assets/images/helvetia_logo.png"
                alt="Logo 1"
                class="h-10"
              />

              <img
                loading="lazy"
                *ngIf="company == 'KSF'"
                src="assets/images/ks_logo.png"
                alt="Logo 1"
                class="h-8"
              />
            </div>
          </div>

          <div
            class="flex flex-col justify-center items-center flex-1 bg-[#9FF0DC] bg-opacity-10 py-10 opacity-0"
          >
            <p class="text-center quantico-text text-6xl font-bold">0</p>

            <p class="text-center quantico-text">
              <span class="text-base">DKK/md.</span>
            </p>
          </div>
          <div
            class="flex flex-col justify-center flex-1 bg-[#9FF0DC] bg-opacity-10 pb-10 px-10 min-h-[128px] opacity-0"
          >
            <p class="text-center font-bold leading-5 text-[#168B7B]">
              Selvrisiko
            </p>

            <p class="text-center font-medium leading-5 text-[#168B7B] pt-4">
              0 DKK
            </p>
          </div>
        </div>
        <div class="flex flex-col opacity-0">
          <div
            class="flex-1 justify-center items-center xl:block lg:hidden md:block sm:hidden"
          ></div>
          <div
            class="flex flex-col justify-center items-center flex-1 text-[#2D3748] py-8"
          >
            <div>
              <p class="py-1">
                <i class="fa-solid fa-circle-check text-teal-450"></i>
                Fri kilometer
              </p>
              <p class="py-1">
                <i class="fa-solid fa-circle-check text-teal-450"></i>
                Førerdækning
              </p>
              <div class="flex">
                <p class="py-1">
                  <i class="fa-solid fa-circle-check text-teal-450"></i>
                  Frit værkstedsvalg
                </p>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col justify-center items-center flex-1 pb-8 opacity-0"
          >
            <button
              class="py-3 px-6 shadow-lg inline-flex items-center gap-x-1 text-sm rounded-full border border-transparent bg-teal-450 text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
            >
              <p class="text-lg font-inter">Vælg</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ######################## -->
<!-- ######################## -->
<!-- ######################## -->
